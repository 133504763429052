import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../03_organisms/Header';
import OverlayHeader from '../../03_organisms/OverlayHeader';

import styles from './index.module.scss';

class StickyNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { isTop } = this.state;
    const pageOffset = window.pageYOffset;

    // We use 55px from top to be sure that user won't see empty
    // space behind of navigation.
    if (isTop && pageOffset > 55) {
      this.setState({ isTop: false });
    } else if (!isTop && pageOffset <= 55) {
      this.setState({ isTop: true });
    }
  }

  render() {
    const { isTop } = this.state;
    const { overlay = false } = this.props;

    return overlay ? (
      <div className={`sticky-nav ${styles['sticky-nav']} ${isTop ? 'top' : ''}`}>
        <OverlayHeader {...this.props} isTop={isTop} />
      </div>
    ) : (
      <>
        <div className={styles['empty-space-behind-nav']} />
        <div className={`sticky-nav ${styles['sticky-nav']} ${isTop ? 'top' : ''}`}>
          <Header {...this.props} />
        </div>
      </>
    );
  }
}

StickyNav.propTypes = {
  overlay: PropTypes.bool,
};

export default StickyNav;
